import flatpickr from "flatpickr";

var init_flatpickr = function() {
    $(".timeinput").flatpickr({
        enableTime: true,
        noCalendar: true,
        dateFormat: "H:i",
        time_24hr: true,
        allowInput: true,
    });
    $(".timeinput").attr('autoComplete', 'off')

    $(".dateinput").flatpickr({
        enableTime: false,
        dateFormat: "Y-m-d",
        allowInput: true,
    });
    $(".dateinput").attr('autoComplete', 'off')

    $(".datetimeinput").flatpickr({
        enableTime: true,
        time_24hr: true,
        dateFormat: "Y-m-d H:i",
        allowInput: true,
    });
    $(".datetimeinput").attr('autoComplete', 'off')
}

$(function () {
    window.init_flatpickr = init_flatpickr
    init_flatpickr()
})

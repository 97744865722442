import "select2/dist/js/select2.min.js";

$.fn.select2.defaults.set("theme", "bootstrap4");

$(function () {

    $.fn.select2.defaults.set("theme", 'bootstrap4');
    $('form select.form-control').each(function() {
        $(this).select2();
    });

});


import Dropzone from "dropzone";
window.Dropzone = Dropzone;

Dropzone.autoDiscover = false;

// function activateDropzone(dzone) {
//     let id = dzone.attr('id');
//     console.log(id)
//     let dropzone = new Dropzone('#' + id,
//         {
//             'url': dzone.data('target'),
//             'params': {
//                 'csrfmiddlewaretoken': dzone.data('csrfmiddlewaretoken'),
//                 'profile': dzone.data('profile'),
//             },
//             'maxFilesize': 32000
//         }
//     );
//     dropzone.on("success", function(file, response) {
//         let input = $('input[name="dropzone_files"]');
//         input.val(input.val() + response.fid + ',');
//     });
// }
//
// $(function () {
//
    // var lang = {
    //     "dropzone": {
    //         "dictDefaultMessage": "Glisser les fichiers ici pour téléverser",
    //         "dictFallbackMessage": "Votre fureteur ne supporte pas le 'glisser/déposer'.",
    //         "dictFallbackText": "Veuillez utilsier le formulaire classique ci-bas pour téléverser vos fichiers.",
    //         "dictFileTooBig": "Votre fichier est trop lourd ({{filesize}}MiB). Taille maximale permise: {{maxFilesize}}MiB.",
    //         "dictInvalidFileType": "Ce type de fichier n'est pas accepté."
    //     }
    // };
    //
    // if ('fr' == $("html").attr("lang")) {
    //     Dropzone.prototype.defaultOptions.dictDefaultMessage = lang.dropzone.dictDefaultMessage;
    //     Dropzone.prototype.defaultOptions.dictFallbackMessage = lang.dropzone.dictFallbackMessage;
    //     Dropzone.prototype.defaultOptions.dictFallbackText = lang.dropzone.dictFallbackText;
    //     Dropzone.prototype.defaultOptions.dictFileTooBig = lang.dropzone.dictFileTooBig;
    //     Dropzone.prototype.defaultOptions.dictInvalidFileType = lang.dropzone.dictInvalidFileType;
    // };

    // $('.dropzone.dynamic').each(function() {
    //     activateDropzone($(this));
    // });
// });


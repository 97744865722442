import "script-loader!datatables.net";
import "script-loader!datatables.net-bs4";
import "script-loader!datatables.net-buttons";
import "script-loader!datatables.net-buttons/js/buttons.html5.js";
import "script-loader!datatables.net-buttons/js/buttons.print.js";
import "script-loader!datatables.net-buttons-bs4";
import "script-loader!datatables.net-responsive";
import "script-loader!datatables.net-select";


$(function () {
    $.extend( $.fn.dataTable.defaults, {
        order: [[ 0, "asc" ]],
        deferRender: true,
        lengthChange: false,
        bAutoWidth: false,
        pageLength: 25
    });

});
